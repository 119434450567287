.drop-active {
  border-color: #6c6;
}

.drop-target {
  border-color: #6c6;
  border-style: dashed;
  border-width: 2px;
}

.can-drop {
  background-color: #6c6;
}

.dropped {
  background-color: #c66;
}
