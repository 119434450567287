.banners-list.ag-theme-alpine .ag-cell-value > [class^='ag-react'] {
  height: 80px;
  padding: 0.2rem;
  display: flex;
  align-items: center;
}

.banners-list.ag-theme-alpine [class^='ag-cell'] {
  display: flex;
  align-items: center;
}
