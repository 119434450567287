@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import url("./icomoon/style.css");

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v126/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* BOLD */
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("../assets/fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Bold-102a.woff2")
      format("woff2"),
    url("../assets/fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Bold-102.woff")
      format("woff"),
    url("../assets/fonts/Atkinson/TTF/Atkinson-Hyperlegible-Bold-102.ttf")
      format("truetype"),
    url("../assets/fonts/Atkinson/EOT/Atkinson-Hyperlegible-Bold-102.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Atkinson/SVG/Atkinson-Hyperlegible-Bold-102.svg")
      format("svg");
  font-weight: 700;
  /* font-style: normal; */
  font-display: block;
}

/* REGULAR */
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("../assets/fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Regular-102a.woff2")
      format("woff2"),
    url("../assets/fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Regular-102.woff")
      format("woff"),
    url("../assets/fonts/Atkinson/TTF/Atkinson-Hyperlegible-Regular-102.ttf")
      format("truetype"),
    url("../assets/fonts/Atkinson/EOT/Atkinson-Hyperlegible-Regular-102.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Atkinson/SVG/Atkinson-Hyperlegible-Regular-102.svg")
      format("svg");
  font-weight: normal;
  /* font-style: normal; */
  font-display: block;
}

/* REGULAR */
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("../assets/fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Regular-102a.woff2")
      format("woff2"),
    url("../assets/fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Regular-102.woff")
      format("woff"),
    url("../assets/fonts/Atkinson/TTF/Atkinson-Hyperlegible-Regular-102.ttf")
      format("truetype"),
    url("../assets/fonts/Atkinson/EOT/Atkinson-Hyperlegible-Regular-102.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Atkinson/SVG/Atkinson-Hyperlegible-Regular-102.svg")
      format("svg");
  font-weight: normal;
  /* font-style: normal; */
  font-display: block;
}

/* GOTHAM-MEDIUM */
@font-face {
  font-family: "gotham";
  font-weight: 400;
  src: url(../assets/fonts/Gotham//GothamRoundedMedium_21022.ttf)
    format("truetype");
}

/* GOTHAM-BOLD */
@font-face {
  font-family: "gotham";
  font-weight: 700;
  src: url(../assets/fonts/Gotham/GothamRoundedBold_21016.ttf)
    format("truetype");
}

/* GOTHAM-BOOK */
@font-face {
  font-family: "gotham";
  font-weight: 600;
  src: url(../assets/fonts/Gotham/GothamRoundedBook_21018.ttf)
    format("truetype");
}

/* GOTHAM-LIGTH */
@font-face {
  font-family: "gotham";
  font-weight: 100;
  src: url(../assets/fonts/Gotham/GothamRoundedLight_21020.ttf)
    format("truetype");
}

/* PASSWORD */
@font-face {
  font-family: "password";
  font-weight: 600;
  src: url(../assets/fonts/Password/password.ttf)
    format("truetype");
  font-display: block;
}

body {
  color: #2c2c2c;
  font-family: "Atkinson Hyperlegible Regular", Arial, Helvetica, sans-serif;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border-top: 0px solid #eee;
  background-color: transparent;
  border-left: 0px solid #eee;
}

::-webkit-scrollbar-thumb {
  height: 3px;
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #ccc;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.Dropdown::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border-top: 0px solid #eee;
  background-color: transparent;
  border-left: 0px solid #eee;
}

.Dropdown::-webkit-scrollbar-thumb {
  height: 3px;
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #ccc;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.Dropdown::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.Dropdown::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tw-text-xxs {
  font-size: 10px;
}

.tw-text-xxxs {
  font-size: 8px;
}

.tw-text-xs {
  font-size: 12px;
}

.tw-text-sm {
  font-size: 14px;
}

.tw-text-base {
  font-size: 16px;
}

.tw-text-lg {
  font-size: 18px;
}

.tw-text-xl {
  font-size: 20px;
}

.tw-text-2xl {
  font-size: 22px;
}

.tw-text-3xl {
  font-size: 24px;
}

.tw-text-4xl {
  font-size: 26px;
}

.tw-text-5xl {
  font-size: 28px;
}

.tw-text-6xl {
  font-size: 30px;
}
